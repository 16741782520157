import { ref } from "vue";

const userEmail = ref(null)
const userType = ref(null)
const userMembership = ref(null)
const chatAccess = ref(null)
const credits = ref(null)

export default function useGlobalData(){

    function setUserEmail(val){
        userEmail.value=val;
    }

    function setUserType(val){
        userType.value=val;
    }

    function setUserMembership(val){
        userMembership.value=val;
    }

    function setCredits(val){
        credits.value=val;
    }

    function setChatAccess(val){
        chatAccess.value=val;
    }

    return {userEmail, setUserEmail, userType, setUserType, userMembership, setUserMembership, credits, setCredits, chatAccess, setChatAccess}
}
